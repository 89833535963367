<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('conversationSettingsTitle') }}</h3>
  </div>
  <license-detail-api
    v-if="license"
    :license="license"
  ></license-detail-api>
  <license-detail-sandbox
    v-if="license"
    :license="license"
  ></license-detail-sandbox>

  <modal-wrapper v-if="license && license.sandbox_integrations" ref="modalIntegrationSettings">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('integrations.form.credentials.title') }}</h5>
          <button
            @click="closeCredentialsModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <DynamicForm
            :schema="integrationFormSchema"
            :initial-field-value="integrationFormInitialData"
            :button-data="integrationFormButton"
            :is-disabled="isIntegrationFormLoading"
            form-class="justify-content-start"
            @submit="integrationFormSendHandler"
          >
          </DynamicForm>
        </div>
      </div>
    </div>
  </modal-wrapper>

  <div class="card" v-if="license && license.sandbox_integrations">
<!--    <div class="card-header">-->
<!--      <h5>{{ $t('forms.senderName.integrationSandboxSection') }}</h5>-->
<!--    </div>-->
    <div
      v-for="(integration, index) in license.sandbox_integrations"
      :key="index"
      class="card-body px-4 py-4"
    >
      <h3 class="align-middle"><img :src="integration.icon" class="mr-2" style="height: 32px" />{{ integration.name }}</h3>
      <p>{{ $t(`integrations.${integration.vendor}.description`) }}</p>
      <table class="table">
        <tbody>
        <tr>
          <td class="align-middle">
            {{ $t('status') }}:
            <span v-if="integration.is_connected" class="text-c-green mb-0">
                {{ $t('integrations.connectedTitle') }}
              </span>
            <span v-if="!integration.is_connected" class="mb-0 text-muted">
                {{ $t('integrations.disconnectedTitle') }}
              </span>
          </td>
          <td class="text-right">
            <button
              v-if="integration.is_connected && !integration.connect_button"
              @click.prevent="() => openIntegrationFormModal(integration.fields, integration.vendor)"
              class="btn btn-primary btn-sm"
              :is-disabled="isIntegrationFormLoading"
            >
              <i class="feather icon-sliders mx-1"></i>
              {{ $t('settings') }}
            </button>
          </td>
        </tr>
        <tr class="text-right">
          <td></td>
          <td class="align-middle">

            <!-- Integration with button -->
            <div v-if="integration.connect_button && !integration.is_connected">
              <a target="_blank" :href="integration.connect_button" class="btn btn-primary btn-sm">{{ $t('connect') }}</a>
            </div>

            <!-- Integration with fields and modal popup -->
            <div v-if="!integration.connect_button && !integration.is_connected">
              <button class="btn btn-primary btn-sm" @click.prevent="() => openIntegrationFormModal(integration.fields, integration.vendor)">{{ $t('connect') }}</button>
            </div>

            <!-- Integration connected -->
            <div v-if="integration.is_connected">
              <modal-wrapper>
                  <template v-slot="{ close }">
                    <confirmation-modal
                      :close="close"
                      :content="$t('integrations.deleteConfirmation')"
                      @confirm="() => removeIntegrationConnectionHandler(integration.vendor)"
                    ></confirmation-modal>
                  </template>
                  <template v-slot:trigger="{ open }">
                    <button
                      v-if="integration.is_connected"
                      @click.prevent="open"
                      class="btn btn-danger btn-sm"
                      :is-disabled="isIntegrationFormLoading"
                    >
                      <i class="feather icon-trash mx-1"></i>
                      {{ $t('integrations.removeTitle') }}
                    </button>
                  </template>
                </modal-wrapper>
              </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="table-separator mb-3"></div>
    </div>
  </div>

  <license-detail-webhooks
    v-if="license && license.webhook"
    :license="license"
  ></license-detail-webhooks>
  <div class="position-relative">
    <spinner-component v-if="isLoading" item-class="spinner-layout-gray"></spinner-component>
    <div v-if="!isLoading" class="card">
      <div class="card-header">
        <h5 class="m-b-10">{{ $t('requestsBalanceNotifications') }}</h5>
      </div>
      <div class="card-body">
        <notification-settings-form
          v-if="!isLoading"
          :has-active-subscription="hasActiveSubscription"
          :init-form="initNotificationForm"
          @form-change="changeNotificationTypeHandler"
        ></notification-settings-form>
        <div v-if="notificationFormState.action === 'send_email_notification' && notificationFormState.count" class="mt-3">
          <hr />
          <add-notification-recipient-form
            :users="contacts"
            :is-loading="isContactFormSending"
            @delete-contact="deleteContactHandler"
            @send-form="sendEmailNotificationHandler"
          ></add-notification-recipient-form>
        </div>
        <div v-if="notificationFormState.action === 'auto_charge' && notificationFormState.count" class="mt-3">
          <hr />
          <add-auto-charge-form
            :init-form="initAutoChargeForm"
            :plan-type="'text'"
            @send-form="sendAutoChargeHandler"
          ></add-auto-charge-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { balanceNotificationEmailCount } from '@/application/utils/limits.js'
import { computed, defineAsyncComponent, ref, onMounted } from 'vue'
import { store } from '@/application/store'
import { getNotificationSettings, saveNotificationSettings, deleteContactFromNotifications, saveNotificationParams } from '@/application/services/notification-settings.js'
import { useNotificationSettingsLogic } from '@/application/composables/notificationSettingsLogic.js'
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'
import { useIntegrationForm } from '@/application/composables/integrationsForm.js'
import DynamicForm from '../forms/DynamicForm.vue'

export default {
  name: 'ConversationApiSettingsPage',
  components: {
    DynamicForm,
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'notification-settings-form': defineAsyncComponent(() => import('@/application/components/forms/NotificationSettingsForm.vue')),
    'add-notification-recipient-form': defineAsyncComponent(() => import('@/application/components/forms/AddNotificationRecipientForm.vue')),
    'add-auto-charge-form': defineAsyncComponent(() => import('@/application/components/forms/AutoChargeForm.vue')),
    'license-detail-api': defineAsyncComponent(() => import('@/application/components/elements/LicenseDetailApi.vue')),
    'license-detail-sandbox': defineAsyncComponent(() => import('@/application/components/elements/LicenseDetailSandbox.vue')),
    'license-detail-webhooks': defineAsyncComponent(() => import('@/application/components/elements/LicenseDetailWebhooks.vue')),
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  setup() {
    const license = computed(() => store.getters.getLicense)
    const isLoading = ref(true)
    const notificationFormState = ref({})
    const hasActiveSubscription = ref(null)
    const initNotificationForm = ref({})
    const initAutoChargeForm = ref({})
    const contacts = ref([])
    const isContactFormSending = ref(false)
    const settingsId = ref(null)
    const { openModal } = useModal()
    const i18n = useI18n()

    const { saveConversationNotificationHandler } = useNotificationSettingsLogic()

    onMounted(() => {
      getNotificationSettings().then(({ data }) => {
        hasActiveSubscription.value = data.settings.is_active_subscriptions
        initNotificationForm.value = {
          count: data.settings.trigger_count,
          action: data.settings.action === 2 ? 'auto_charge' : 'send_email_notification',
        }
        notificationFormState.value = initNotificationForm.value
        initAutoChargeForm.value = {
          plan: data.settings.plan,
          subscription: data.settings.subscription,
        }
        contacts.value = data.settings.emails
        settingsId.value = data.settings.id
        isLoading.value = false
      })
    })

    const senderNameId = computed(() => license.value.sandbox_sender_name.sender_name_id)
    const {
      openIntegrationFormModal,
      modalIntegrationSettings,
      integrationFormSchema,
      closeCredentialsModal,
      integrationFormButton,
      isIntegrationFormLoading,
      integrationFormSendHandler,
      removeIntegrationConnectionHandler,
      integrationFormInitialData,
    } = useIntegrationForm(senderNameId)

    return {
      isLoading,
      contacts,
      license,
      notificationFormState,
      hasActiveSubscription,
      initNotificationForm,
      initAutoChargeForm,
      isContactFormSending,
      changeNotificationTypeHandler: (data) => {
        notificationFormState.value = data
        saveNotificationParams({ ...data, id: settingsId.value, type: 'conversation' })
      },
      sendAutoChargeHandler: (data) => {
        const dataSend = {
          ...data,
          ...notificationFormState.value,
        }
        saveConversationNotificationHandler(dataSend, 'conversation')
      },
      sendEmailNotificationHandler: (data, callback) => {
        if (contacts.value.length >= balanceNotificationEmailCount) {
          openModal(
            i18n.t('error'),
            i18n.t('errorMaxNotificationEmails')
          )
          return
        }

        const dataSend = {
          ...data,
          ...notificationFormState.value,
        }
        isContactFormSending.value = true
        saveNotificationSettings({ ...dataSend, type: 'conversation' }).then(() => {
          contacts.value.push(data.email)
          callback()
          isContactFormSending.value = false
        }).catch(err => {
          console.log(err)
          isContactFormSending.value = false
        })
      },
      deleteContactHandler: (contact) => {
        const dataSend = {
          id: settingsId.value,
          contact: contact,
        }
        deleteContactFromNotifications(dataSend).then(({ data }) => {
          contacts.value = data.contacts
        }).catch(err => {
          console.log(err)
        })
      },
      openIntegrationFormModal,
      modalIntegrationSettings,
      integrationFormSchema,
      closeCredentialsModal,
      integrationFormButton,
      isIntegrationFormLoading,
      integrationFormSendHandler,
      removeIntegrationConnectionHandler,
      integrationFormInitialData,
    }
  },
}
</script>
